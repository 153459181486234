/*
 * Application
 */

$(document).tooltip({
    selector: "[data-toggle=tooltip]"
})

$(document).ready(function() {

    $(".owl-carousel").owlCarousel({

        autoPlay: 3000,
        items : 3,
        itemsDesktop : [1199,3],
        itemsDesktopSmall : [979,3],
        center: true,
        dots: true,
        nav:true,
        loop:true,
        responsive: {
            600: {
                items: 3
            }
        },
        nav: true,
        navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
    });

});

$(function () {
    $('.combostar').on('change', function () {
        //alert($(this).val());
    });
    $('.combostar').combostars();
});


function resetForm(data){
    $(data).find(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
    $(data).find(':checkbox, :radio').prop('checked', false);
}

$(document).ready(function() {
    loadornot();
});


function get_cookie(Name) {
    var search = Name + "="
    var returnvalue = "";
    var  end = "";
    if (document.cookie.length > 0) {
        offset = document.cookie.indexOf(search)
        // if cookie exists
        if (offset != -1) {
            offset += search.length
            // set index of beginning of value
            end = document.cookie.indexOf(";", offset);
            // set index of end of cookie value
            if (end == -1) end = document.cookie.length;
            returnvalue=unescape(document.cookie.substring(offset, end))
        }
    }
    return returnvalue;
}

function loadornot(){
    if (get_cookie('popunder')==''){
        $("#disclaimer").modal({backdrop: "static"});
    }
}

//if close button is clicked
$('#iunderstand').click( function() {
    if (get_cookie('popunder')==''){
        $("#disclaimer").modal("hide")
        document.cookie="popunder=yes"
    }
    return false;
});


